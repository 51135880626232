<template>
  <div v-if="bottomShow===1">
    <div class="height120"></div>
    <div class="bottom-banner">
      <img src="./imgs/banner-bottom.png" style="width:100%;height:100%;" />
      <img src="./imgs/icon_close.png" class="icon_close" @click="changeBottomShow" />
      <div class="openNewPage" @click="openApplyPage"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerBottom',
  props: {},
  data() {
    return {
      bottomShow: 1
    };
  },
  mounted() {},
  created() {},
  methods: {
    openApplyPage() {
      // window.open('http://h5.beadata.cn/#/apply-settle');
      window.open('http://192.168.3.108:31007/#/apply-settle');
    },
    changeBottomShow() {
      this.bottomShow = 2;
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;
.bottom-banner {
  width: 100%;
  height: 120 * $px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  background-color: #fff;
}
.icon_close {
  width: 18 * $px;
  height: 18 * $px;
  position: absolute;
  top: 7 * $px;
  right: 16 * $px;
}
.openNewPage {
  width: 150 * $px;
  height: 46 * $px;
  position: absolute;
  bottom: 24 * $px;
  right: 16 * $px;
}
.height120 {
  width: 100%;
  height: 90 * $px;
}
</style>
