<template>
  <div>
    <van-collapse v-model="bottomListSelect" @change="collapseChange" accordion>
      <van-collapse-item
        v-for="item in bottomList"
        :key="item.id"
        :title="item.name"
        :name="item.id"
      >
        <div class="bottomListShowTab">
          <div
            v-for="fontItem in item.list"
            :key="fontItem"
            class="bottomListShowTabItem"
          >{{fontItem}}</div>
        </div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
export default {
  name: 'BottomList',
  props: {
    bottomList: {
      type: Array,
      required: true
    },
    bottomListSelect: {
      type: Number,
      required: true
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    collapseChange(value) {
      this.$emit('changeBottomListSelect', value);
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;
/deep/ .van-collapse-item__content {
  padding: 0px;
}
/deep/ .van-cell {
  padding: 12 * $px 0.42667rem;
  background-color: #1e1e1e;
  color: #ffffff !important;
}
/deep/ .van-cell:not(:last-child)::after {
  border-bottom: none !important;
}
/deep/ [class*='van-hairline']::after {
  border: 0.6 * $px solid #333333 !important;
}
/deep/ .van-collapse-item--border:after {
  border-top: 0.6 * $px solid #333333 !important;
}
.bottomListShowTab {
  width: 100%;
  background-color: #333333;
  overflow: hidden;
}
.bottomListShowTabItem {
  width: 25%;
  height: 65 * $px;
  color: #ffffff;
  font-size: 12 * $px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
